import React from 'react';
import BlogHeader from './blog_header'
import BlogList from './blog_list'


function Blogs() {
    return (
        <div>
            <BlogHeader />
            <BlogList />

        </div>
    )

}
export default Blogs