import React from 'react';

class Home extends React.Component {
    render() {
        return (
            <div class="flex h-screen">
                <div class="m-auto">
                    <h3>Sobit Neupane</h3>
                </div>
            </div>
        )
    }
}

export default Home;