import React from 'react';
import ProjectHeader from './project_header';

class Projects extends React.Component {
    render() {
        return (
            <ProjectHeader />
        )
    }
}
export default Projects;